import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import PageHeader from "../components/PageHeader"
import Layout from "../components/layout"
import ParseHTML from "../particles/parseHTML"

const PostPage = props => {
  console.log(props)
  const { title, PostDescription, content } = props.pageContext

  return (
    <Layout>
      <SEO title={title} />
      <PageHeader title={title} subtitle={PostDescription.description} />
      <div id="blog" class="blog-area pt-30 pb-110">
        <div className="container">
          <div className="row" style={{ flexDirection: "column" }}>
            {ParseHTML(content)}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PostPage
